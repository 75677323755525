import { fetchAuthSession } from 'aws-amplify/auth'

export async function isAuthenticated() {
  try {
    await fetchAuthSession()
    return true
  } catch (error) {
    return false
  }
}
