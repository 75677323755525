import {
  Box,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  alpha,
  useTheme
} from '@mui/material'

import NextLink from 'next/link'
import { Fragment } from 'react'
import { getPrivacyLink, getTermsLink, getWorkspacesLink } from 'utils'
import Facebook from '~ui-components/assets/svg/Facebook'
import Linkedin from '~ui-components/assets/svg/Linkedin'
import Twitter from '~ui-components/assets/svg/Twitter'

import { Mapstack } from '~ui-components/assets/svg/logos/Mapstack'
import Container from '~ui-components/components/atoms/Container'

const { description, copyright, socials, navigations } = {
  description: (
    <Fragment>
      When you visit or interact with our sites, services or tools, we or our
      authorised service providers may use cookies for storing information to
      help provide you with a better, faster and safer experience and for
      marketing purposes.
    </Fragment>
  ),
  copyright: '©2023 Mapstack. 1 Victoria Street, Bristol, England, BS1 6AA, UK',
  socials: [
    {
      label: 'Twitter',
      href: 'https://twitter.com/mapstack_io',
      icon: (
        <SvgIcon>
          <Twitter />
        </SvgIcon>
      )
    },
    {
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/company/mapstack/',
      icon: (
        <SvgIcon>
          <Linkedin />
        </SvgIcon>
      )
    },
    {
      label: 'Facebook',
      href: 'https://www.facebook.com/mapstack.io',
      icon: (
        <SvgIcon>
          <Facebook />
        </SvgIcon>
      )
    }
  ],
  navigations: [
    {
      label: 'Privacy Policy',
      href: getPrivacyLink()
    },
    {
      label: 'Terms of Service',
      href: getTermsLink()
    },
    {
      label: 'Workspaces',
      href: getWorkspacesLink()
    }
  ]
}

const Footer = (props) => {
  const theme = useTheme()

  return (
    <Box
      component='footer'
      {...props}
    >
      <Container position='relative'>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box
                display='flex'
                underline='none'
                title='mapstack'
                height={24}
                width={35}
              >
                <Mapstack
                  height='100%'
                  width='100%'
                />
              </Box>

              <Stack
                direction='row'
                spacing={1}
                pt={{ xs: 2 }}
              >
                {socials.map((social) => (
                  <Box
                    key={social.label}
                    component={IconButton}
                    href={social.href}
                    size='small'
                  >
                    {social.icon}
                  </Box>
                ))}
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              textAlign='center'
              variant='caption'
              color='text.secondary'
              component='p'
              gutterBottom
            >
              {copyright}
            </Typography>

            <Typography
              align='center'
              variant='caption'
              color='text.secondary'
              component='p'
            >
              {description}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
          >
            {navigations.map((navigation, index) => {
              return (
                <Fragment key={navigation.label}>
                  <Box>
                    <NextLink
                      href={navigation.href}
                      passHref
                      legacyBehavior
                    >
                      <Typography
                        as='a'
                        variant='subtitle2'
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                      >
                        {navigation.label}
                      </Typography>
                    </NextLink>
                  </Box>

                  {index < navigations.length - 1 && (
                    <Box
                      width='1px'
                      height='1rem'
                      mx={2.5}
                      backgroundColor={alpha(
                        theme.palette.secondary.contrastText,
                        0.2
                      )}
                    />
                  )}
                </Fragment>
              )
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export { Footer }
export default Footer
