import bbox from '@turf/bbox'
import { Percentiles, Resolutions } from '../../types'
import { Feature } from 'geojson'

const DEFAULT_PERCENTILE_CHECKS: Percentiles = [35, 65]

function percentile(sortedArray: number[], percentile: number): number {
  if (sortedArray.length === 0) {
    throw new Error('sortedArray is empty')
  }
  const index = Math.ceil((sortedArray.length * percentile) / 100) - 1
  const element = sortedArray[index]

  if (element !== undefined) {
    return element
  } else {
    throw new Error(
      `Percentile ${percentile}'s index ${index} not found in sortedArray of length ${sortedArray.length}`
    )
  }
}

function percentiles(
  arr: number[],
  percentiles: Percentiles = DEFAULT_PERCENTILE_CHECKS
): Percentiles {
  // sort array so is order for percentile
  const sorted: Array<number> = arr.slice().sort((a, b) => a - b)
  const percs: Percentiles = [0, 0]

  // get percentiles
  for (let i = 0; i < percentiles.length; i++) {
    const percentileValue = percentiles[i]
    if (!percentileValue || percentileValue === undefined)
      throw new Error(`percentile ${percentile} not found`)
    const value = percentile(sorted, percentileValue)
    percs[i] = value
  }
  return percs
}

function getMediumValue(bbox: [number, number, number, number]): number {
  const xDiff = bbox[2] - bbox[0]
  const yDiff = bbox[3] - bbox[1]

  const diff = Math.min(xDiff, yDiff)

  if (diff > 7) {
    return 1
  } else if (diff > 5) {
    return 2 // Very large area
  } else if (diff > 2) {
    return 3 // Large area
  } else if (diff > 1) {
    return 4 // Moderate area
  } else if (diff > 0.5) {
    return 5 // Medium area
  } else if (diff > 0.25) {
    return 6 // Smaller area
  } else if (diff > 0.1) {
    return 7 // Small area
  } else {
    return 8 // Very small area
  }
}

function generateResolutions(geojsonFeatures: Feature[]): Resolutions {
  let xcoords: number[] = []
  let ycoords: number[] = []

  if (geojsonFeatures[0]!.geometry.type == 'Point') {
    geojsonFeatures.map((feature: Feature) => {
      //@ts-ignore
      xcoords.push(feature.geometry.coordinates[0])
      //@ts-ignore
      ycoords.push(feature.geometry.coordinates[1])
    })
  } else if (
    geojsonFeatures[0]!.geometry.type == 'Polygon' ||
    geojsonFeatures[0]!.geometry.type == 'MultiPolygon'
  ) {
    geojsonFeatures.map((feature: Feature) => {
      const boundingBox = bbox(feature)
      xcoords.push(boundingBox[0], boundingBox[2])
      ycoords.push(boundingBox[1], boundingBox[3])
    })
  } else {

    throw new Error(`Somthign wrong with the type of data ${JSON.stringify(geojsonFeatures[0]!.geometry)}`)

  }

  const xPercChecks: Percentiles = percentiles(xcoords)
  const yPercChecks: Percentiles = percentiles(ycoords)

  const constrainedBbox: [number, number, number, number] = [
    xPercChecks[0],
    yPercChecks[0],
    xPercChecks[1],
    yPercChecks[1]
  ]

  const medValue = getMediumValue(constrainedBbox)

  return {
    large: medValue - 1,
    medium: medValue,
    small: medValue + 1
  }
}

export { generateResolutions }
