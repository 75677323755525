import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import type { FC, ReactNode } from 'react'
import {
  GetUserByIdResult,
  useGetCurrentUserId,
  useGetUserById
} from '~user-auth/queries/useGetUserById'

export type AuthState = {
  status: 'idle' | 'loading' | 'ready'
  initialized: boolean
  loading: boolean
  authenticated: boolean
  ready: boolean
  userId?: string
  user: GetUserByIdResult | null
  token: string | null
  isStaff: boolean
}

export const initialState: AuthState = {
  status: 'idle',
  initialized: false,
  loading: false,
  authenticated: false,
  ready: false,
  user: null,
  token: null,
  isStaff: false
}

export type AuthContext = AuthState & {
  setAuth: (auth: Partial<AuthState>) => void
  resetAuth: () => void
}

export const AuthContext = createContext<AuthContext>({
  ...initialState,
  setAuth: () => {},
  resetAuth: () => {}
})

export const AuthProvider: FC<{
  children: ReactNode
}> = (props) => {
  const { children } = props

  const { data: currentId } = useGetCurrentUserId()
  const { data: user, status } = useGetUserById(currentId)

  const [state, setState] = useState<AuthState>({
    ...initialState,
    ...(status === 'pending'
      ? { status: 'loading', loading: true, ready: false }
      : {}),
    ...(status === 'success'
      ? {
          userId: user?.id,
          status: 'ready',
          authenticated: true,
          initialized: true,
          loading: false,
          ready: true,
          user,
          token: null,
          isStaff:
            !!user?.email &&
            ['@mangomap.com', '@mapstack.io'].some((domain) =>
              user.email.includes(domain)
            )
        }
      : {})
  })

  const setAuth = useCallback<AuthContext['setAuth']>((auth) => {
    setState((prevState) => {
      return { ...prevState, ...auth }
    })
  }, [])

  const resetAuth = useCallback<AuthContext['resetAuth']>(() => {
    setState(initialState)
  }, [])

  useEffect(() => {
    setAuth({
      ...(status === 'pending'
        ? { status: 'loading', loading: true, ready: false }
        : {}),
      ...(status === 'success'
        ? {
            userId: user?.id,
            status: 'ready',
            authenticated: true,
            initialized: true,
            loading: false,
            ready: true,
            user,
            token: null,
            isStaff:
              !!user?.email &&
              ['@mangomap.com', '@mapstack.io'].some((domain) =>
                user.email.includes(domain)
              )
          }
        : {})
    })
  }, [setAuth, status, user])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setAuth,
        resetAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
