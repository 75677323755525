import { Container, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import Layout from '~ui-components/components/organisms/AppLayout'

import MapOpenSearchResults from '~ui-components/components/organisms/MapOpenSearchResults'

const defaultSlots = {
  SearchResults: MapOpenSearchResults
}

const MapOpenSearch = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const Main = (
    <Container
      component={Stack}
      gap={2}
      sx={{ maxWidth: '1096px !important' }}
      maxWidth={false}
    >
      <Slots.SearchResults {...slotProps?.SearchResults} />
    </Container>
  )

  return (
    <Layout
      gutterTop
      slots={{
        Header: slots.Header,
        Main
      }}
    />
  )
}

const MapOpenSearchPropTypes = {
  slots: PropTypes.object,
  slotProps: PropTypes.object
}

MapOpenSearch.propTypes = MapOpenSearchPropTypes

export { MapOpenSearch, MapOpenSearchPropTypes }
export default MapOpenSearch
