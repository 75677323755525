import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export const getQueryParamsKey = () => ['getQueryParams']

export function useQueryParams() {
  const { query } = useRouter()
  const { data: ssrQuery } = useQuery({
    queryKey: getQueryParamsKey()
  })

  return (query || ssrQuery) as Record<string, any>
}
