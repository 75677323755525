import { signOut as amplifySignOut, signInWithRedirect } from 'aws-amplify/auth'
import { useRouter } from 'next/router'

import { baseUrl, getSearchLink } from 'utils'
import { useAuth } from '~user-auth/contexts/auth'
import { postSignInRedirect } from '~user-auth/storages/postSignInRedirect'
import { postSignOutRedirect } from '~user-auth/storages/postSignOutRedirect'

type GoogleMethod = 'Google'
type FacebookMethod = 'Facebook'

type IdentifyProvider = GoogleMethod | FacebookMethod

type AuthOptions = {
  provider?: IdentifyProvider
  redirect?: string
}

const useFederatedAuth = () => {
  const router = useRouter()

  const { setAuth } = useAuth()

  const gotoSignInPage = ({ redirect }: { redirect?: string } = {}) => {
    postSignInRedirect.save(redirect || window.location.href)
    router.push('/sign-in')
  }

  const signIn = async (options: AuthOptions) => {
    const { provider = 'Google', redirect } = options || {}

    if (redirect) {
      const toUrl = `${baseUrl()}${
        redirect.startsWith('/') ? redirect : `/${redirect}`
      }`
      postSignInRedirect.save(toUrl)
    }

    setAuth({ loading: true })

    try {
      await signInWithRedirect({ provider })
    } catch (error: any) {
      if (error?.name === 'UserAlreadyAuthenticatedException') {
        router.replace(getSearchLink())
      }
    }
  }

  const signOut = async (options?) => {
    const { preventRedirect = false } = options || {}

    if (preventRedirect) {
      postSignOutRedirect.save(window.location.href)
    }

    try {
      setAuth({
        user: null,
        token: null,
        authenticated: false
      })

      await amplifySignOut()
    } catch {
      // Ignore Auth SignOut Warning Message
    }
  }

  return { signIn, signOut, gotoSignInPage }
}

export { useFederatedAuth }
export default useFederatedAuth
